import React, { useEffect, useState } from "react";
import NavSideBar from "../sidebar/NavSideBar";
import Box from "@mui/material/Box";
import NextNProgress from "nextjs-progressbar";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { setDrawerState, setLanguage, setTenant } from "store/actions/layout";
import { useRouter } from "next/router";
import { useStoreState } from "store/hooks";
import { getFavico, WHITELABEL_INSTANCES } from "lib/util";
import MessagePredictionDialog from "components/MessagePredictionDialog";
import AdminDialog from "features/admin/components/AdminDialog";
import RequestSnackbar from "components/common/RequestSnackbar";

export interface CopilotBaseLayoutProps {
  children: React.ReactNode;
}

export default function CopilotBaseLayout(props: CopilotBaseLayoutProps) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { locale, query } = router;
  const { tenant } = query;
  const language = useStoreState((state) => state.language);
  const stateTenant = useStoreState((state) => state.tenant);
  const drawerState = useStoreState((state) => state.drawerState);
  const [messagePredictionDialogOpen, setMessagePredictionDialogOpen] =
    useState(false);
  const [advancedOptionsDialog, setAdvancedOptionsDialog] = useState(false);
  useEffect(() => {
    if (!language && dispatch && locale) {
      dispatch(setLanguage(locale));
    }
  }, [locale, dispatch, language]);

  useEffect(() => {
    if (!stateTenant && dispatch && tenant) {
      if (typeof tenant === "string") {
        dispatch(setTenant(tenant.toLowerCase()));
      }
    }
  }, [stateTenant, tenant, dispatch]);

  useEffect(() => {
    if (
      document &&
      !WHITELABEL_INSTANCES.includes((tenant as string)?.toLowerCase())
    ) {
      const head = document.getElementsByTagName("head")[0];
      const link = document.createElement("link");
      link.rel = "shortcut icon";
      link.href = getFavico();
      head.appendChild(link);
    }
  }, [tenant]);
  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        {tenant && language && props.children}
        <MessagePredictionDialog
          open={messagePredictionDialogOpen}
          onClose={() => setMessagePredictionDialogOpen(false)}
        />
        <AdminDialog
          open={advancedOptionsDialog}
          onClose={() => setAdvancedOptionsDialog(false)}
        />
        <RequestSnackbar />
      </Box>
    </>
  );
}
